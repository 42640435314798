define("discourse/plugins/discourse-presence/discourse/services/composer-presence-manager", ["exports", "@ember/runloop", "@ember/service", "discourse-common/config/environment"], function (_exports, _runloop, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const PRESENCE_CHANNEL_PREFIX = "/discourse-presence";
  const KEEP_ALIVE_DURATION_SECONDS = 10;
  let ComposerPresenceManager = _exports.default = (_class = class ComposerPresenceManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "presence", _descriptor, this);
    }
    notifyState(intent, id) {
      if (this.siteSettings.allow_users_to_hide_profile && this.currentUser.user_option.hide_profile_and_presence) {
        return;
      }
      if (intent === undefined) {
        return this.leave();
      }
      if (!["reply", "whisper", "edit"].includes(intent)) {
        throw `Unknown intent ${intent}`;
      }
      const state = `${intent}/${id}`;
      if (this._state !== state) {
        this._enter(intent, id);
        this._state = state;
      }
      if (!(0, _environment.isTesting)()) {
        this._autoLeaveTimer = (0, _runloop.debounce)(this, this.leave, KEEP_ALIVE_DURATION_SECONDS * 1000);
      }
    }
    leave() {
      this._presentChannel?.leave();
      this._presentChannel = null;
      this._state = null;
      if (this._autoLeaveTimer) {
        (0, _runloop.cancel)(this._autoLeaveTimer);
        this._autoLeaveTimer = null;
      }
    }
    _enter(intent, id) {
      this.leave();
      let channelName = `${PRESENCE_CHANNEL_PREFIX}/${intent}/${id}`;
      this._presentChannel = this.presence.getChannel(channelName);
      this._presentChannel.enter();
    }
    willDestroy() {
      this.leave();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});